import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import SubmitButton from 'app/components/buttons/SubmitButton';
import TextInput from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import './AddFieldPanel.scss';
import { FIELD_TYPE, type IFullCase } from '../../mobxStore/types';
import AmplService from '../../services/amplService/amplService';
import { useRootStore } from '../../mobxStore';

interface IProps {
  isPanelOpen: boolean;
  setIsPanelOpen: (isPanelOpen: boolean) => void;
  handleAddField: (fieldName: string, fieldType: FIELD_TYPE) => void;
}

const AddFieldPanel = (props: IProps): React.JSX.Element => {
  const { isPanelOpen, setIsPanelOpen, handleAddField } = props;
  const { t } = useTranslation();
  const [fieldName, setFieldName] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const isSubmitDisabled = selectedType === null || fieldName === null || fieldName === '';
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedType((event.target as HTMLInputElement).value);
  };

  const handleClose = (): void => {
    setIsPanelOpen(false);
  };

  const handleSubmit = async (): Promise<void> => {
    handleAddField(fieldName, selectedType as FIELD_TYPE);
    setSelectedType(null);
    setFieldName('');
    setIsPanelOpen(false);
    AmplService.sendCaseEvent(
      AmplService.EVENTS.EDIT_PREFERENCES_ADD_FIELD_PANEL_SUBMIT,
      openedCase?.basicCase,
      {
        [AmplService.ATTRIBUTES.FIELD_ID]: fieldName,
        [AmplService.ATTRIBUTES.FIELD_TYPE]: selectedType
      }
    );
  };

  const getCmp = (): React.JSX.Element => {
    return (
      <div className="add-field-panel">
        <div className="title">{t('addFieldPanelTitle')}</div>
        <div className="content">
          <div className="field">
            <div className="label">{t('fieldNameLabel')}</div>
            <TextInput
              // when the input is focused, the cursor should be at the end of the text
              onFocus={e => {
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                );
              }}
              variant="standard"
              className="field-name-input"
              placeholder={t('newFieldNamePlaceholder').toString()}
              InputProps={{
                disableUnderline: true
              }}
              onChange={e => {
                setFieldName(e.target.value);
              }}
              value={fieldName}
            />
          </div>
          <div className="field">
            <div className="label">{t('fieldTypeLabel')}</div>
            <RadioGroup
              className="type-radio-select"
              value={selectedType}
              onChange={handleTypeChange}
            >
              <FormControlLabel
                value={FIELD_TYPE.SELECT}
                control={<Radio />}
                label={t('multiOption')}
              />
              <FormControlLabel value={FIELD_TYPE.TEXT} control={<Radio />} label={t('freeText')} />
            </RadioGroup>
          </div>
          <SubmitButton
            id="new-field-submit-button"
            classNames="submit-button"
            title="add"
            submitFunction={handleSubmit}
            isDisabled={isSubmitDisabled}
          />
        </div>
      </div>
    );
  };

  const cmp = getCmp();

  return <ModalScreen isOpen={isPanelOpen} content={cmp} handleClose={handleClose} />;
};

export default AddFieldPanel;
