import React from 'react';
import { useController } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IDynamicField, ISelectOption } from 'app/mobxStore/types';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import ArrowDownIcon from 'app/assets/icons/ArrowDown_icon';
import variables from 'app/utils/variables.module.scss';
import { fieldNeedsAttention } from './helper';
import { useRootStore } from '../../mobxStore';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
}

// this is single only select
const DynamicSelect = (props: IProps): React.JSX.Element => {
  const { control, field: dField } = props;
  const {
    field: { value, onChange, onBlur, ref }
  } = useController({
    name: dField.id,
    control
  });
  const { caseStore, userStore } = useRootStore();
  const { openedCase } = caseStore;

  if (!openedCase) {
    throw new Error('No case found');
  }

  // when adding new field the value is null
  const fixedValue = value ?? [];
  const selectValue =
    fixedValue.length > 0
      ? fixedValue.map((v: string) => ({
          label: dField.options.find(o => o.value === v)?.label,
          value: v
        }))[0]
      : null;

  return (
    <FormControl component="fieldset">
      <div className="form-dynamic-select">
        <div
          className={clsx('form-dynamic-select-label', {
            needsAttention: fieldNeedsAttention(
              dField,
              userStore.loggedInUser.data.id,
              caseStore.currentUserPrevLastSeen
            )
          })}
        >
          <div className="label-container">{dField.label}</div>
        </div>
        <Autocomplete
          isOptionEqualToValue={(option: ISelectOption, value: ISelectOption) => {
            return option.value === value.value;
          }}
          value={selectValue}
          getOptionLabel={option => option.label}
          onChange={(e, selectedValue) => {
            if (selectedValue === null) {
              onChange([]);
              return;
            }
            onChange([selectedValue.value]);
          }}
          onBlur={onBlur}
          disablePortal
          className="autocomplete"
          ref={ref}
          options={dField.options}
          renderOption={(props, option) => {
            return (
              <li {...props} className="list-option">
                {option.label}
              </li>
            );
          }}
          PaperComponent={({ children }) => (
            <Paper
              style={{
                width: '100%',
                height: 'auto',
                boxShadow: 'none',
                borderRadius: 0
              }}
            >
              {children}
            </Paper>
          )}
          popupIcon={<ArrowDownIcon height={7} stroke={variables.grey6} />}
          renderInput={params => <TextField className="autocomplete-input" {...params} />}
        />
      </div>
    </FormControl>
  );
};

export default observer(DynamicSelect);
