import React from 'react';
import Form from '../form/Form';
import type { Control, FormState } from 'react-hook-form/dist/types/form';
import type { IDynamicField, IDynamicFieldValues } from 'app/mobxStore/types';
import { observer } from 'mobx-react-lite';
import { getIsPatientField } from '../../../utils/form/shared';

interface IProps {
  fields: IDynamicField[];
  control: Control<IDynamicFieldValues>;
  allValues: IDynamicFieldValues;
  setFocus: (id: string) => void;
  formState: FormState<IDynamicFieldValues>;
  submitChange: boolean;
  showPatientFields: boolean;
  isEditTemplate?: boolean;
  fieldsOrder?: string[];
}

const BriefForm = (props: IProps): React.JSX.Element => {
  const {
    fieldsOrder,
    formState,
    setFocus,
    allValues,
    fields,
    control,
    submitChange,
    showPatientFields,
    isEditTemplate
  } = props;
  const getFields = (): IDynamicField[] => {
    if (showPatientFields) {
      return fields;
    }

    const filteredFields = fields.filter(field => !getIsPatientField(field.id));
    return filteredFields;
  };

  return (
    <>
      <Form
        fieldsOrder={fieldsOrder}
        formState={formState}
        setFocus={setFocus}
        allValues={allValues}
        fields={getFields()}
        control={control}
        submitChange={submitChange}
        isEditTemplate={isEditTemplate}
      />
    </>
  );
};

export default observer(BriefForm);
