import { type RefObject, useEffect, useRef } from 'react';

// provide a string id to specify an element that should not trigger the callback

export const useOutsideClick = (
  callback: () => void,
  id: string | undefined
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (id && ref.current && (event.target as HTMLElement).id === id) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
