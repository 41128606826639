import React from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { OTHER_OPTION } from '../../../utils/form/shared';
import PencilIcon from '../../assets/icons/Pencil_icon';
import type { IFieldValuesEdits, IFullCase, ISelectOption } from '../../mobxStore/types';
import type { ControllerRenderProps } from 'react-hook-form';
import { useRootStore } from '../../mobxStore';
import { useLongPress } from 'use-long-press';
import AmplService from '../../services/amplService';
import CloseIcon from '../../assets/icons/Close_icon';
import variables from '../../utils/variables.module.scss';
import { useOutsideClick } from '../../utils/useClickOutside';
import apiService from '../../services/apiService/apiService';
import { observer } from 'mobx-react-lite';
import amplService from '../../services/amplService/amplService';

interface IProps {
  option: ISelectOption;
  isSelected: boolean;
  value: string;
  toggleValue: (
    option: ISelectOption,
    value: string[],
    setValue: (...event: any[]) => void
  ) => void;
  setIsEditing: (isEditing: boolean) => void;
  field: ControllerRenderProps<any, string>;
  isLast: boolean;
  isEditTemplate: boolean;
}

const AddYourOwnChip = (props: IProps): React.JSX.Element => {
  const { isEditTemplate, isLast, field, option, isSelected, value, toggleValue, setIsEditing } =
    props;
  const displayOther = value !== '';
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const removeChipRef = useOutsideClick(() => {
    caseStore.setRemoveChipsMode(false);
  }, 'remove-chip-button');

  const bindLongPress = useLongPress(
    () => {
      AmplService.sendEvent(AmplService.EVENTS.CASE_OPTION_LONG_PRESS);
      caseStore.setRemoveChipsMode(!caseStore.isRemoveChipsMode);
    },
    { threshold: 600 }
  );

  const handleRemoveChipForAddedField = (): void => {
    const fieldId = field.name.replace(/-other$/, '');
    const addedField = openedCase.addedFields.find(f => f.id === fieldId);
    if (!addedField) {
      return;
    }
    const newOptions = addedField.otherFieldProps.options.filter(o => o !== option.value);
    openedCase.updateUserFieldOptionsToStore(fieldId, newOptions);
  };
  const handleRemoveChip = (): void => {
    const fieldId = field.name.replace(/-other$/, '');
    if (isSelected) {
      toggleValue(option, field.value, field.onChange);
    }
    const fv = openedCase.referencedData.fieldValues.get(fieldId);

    if (!fv) {
      handleRemoveChipForAddedField();
      return;
    }

    const object: IFieldValuesEdits = {
      deleted: true,
      procedureId: openedCase.basicCase.data.procedureId as string,
      siteId: openedCase.basicCase.data.siteId,
      fieldId,
      value: option.value
    };
    if (!isEditTemplate) {
      void apiService.upsertFieldValuesEdits(object);
    }
    openedCase.updateFieldValuesEditsToStore(fieldId, object);
    amplService.sendCaseEvent(amplService.EVENTS.CASE_OPTION_REMOVED, openedCase.basicCase, {
      [amplService.ATTRIBUTES.FIELD_ID]: fieldId,
      [amplService.ATTRIBUTES.OPTION]: option.value
    });
  };

  return (
    <div className="chip-button-wrapper" key={option.value}>
      <Button
        key={option.value}
        className={clsx('btn chip-button toggle-chip other-chip', {
          selected: isSelected && value,
          isNotClickable: caseStore.isRemoveChipsMode
        })}
        endIcon={option.value === OTHER_OPTION && <PencilIcon className="other-icon" height={14} />}
        onClick={async () => {
          if (caseStore.isRemoveChipsMode) {
            return;
          }
          if (!isLast) {
            toggleValue(option, field.value, field.onChange);
            return;
          }
          if (!isSelected) {
            toggleValue(option, field.value ?? [], field.onChange);
          }
          setIsEditing(true);
        }}
        {...bindLongPress()}
      >
        {displayOther ? value : option.label}
      </Button>
      {caseStore.isRemoveChipsMode && !isLast && (
        <div className="remove-chip-button-wrapper" ref={removeChipRef}>
          <Button
            id="remove-chip-button"
            className={clsx('btn remove-chip-button')}
            onClick={() => {
              handleRemoveChip();
            }}
          >
            <div className="icon-wrapper">
              <CloseIcon stroke={variables.white} />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default observer(AddYourOwnChip);
