import { useEffect, useRef } from 'react';
import {
  initTemplateRecommendations,
  recommendTemplateAdd,
  recommendTemplateRemove,
  recommendTemplateReplace,
  recommendTemplateReplaceText
} from './templateRecommendations';
import type { IDynamicFieldValue, IFieldRecommendation, IFullCase } from '../../../mobxStore/types';
import { useRootStore } from '../../../mobxStore';

interface IProps {
  case: IFullCase;
}

function useTemplateRecommendations(props: IProps): {
  evaluateValueToRecommend: (
    fieldId: string,
    values: IDynamicFieldValue,
    otherValues: IDynamicFieldValue | null,
    type: string,
    multi: boolean,
    oldValue: IDynamicFieldValue,
    oldOtherValue: IDynamicFieldValue | null
  ) => IFieldRecommendation | null;
} {
  const fieldsSummaryRef = useRef({});
  const { case: kase } = props;
  const { careTeamDefaultsStore } = useRootStore();

  if (!kase.basicCase.data.procedureId) {
    throw new Error('No procedure id');
  }

  const savedDefault = careTeamDefaultsStore.getDefault(
    kase.basicCase.data.attendingId,
    kase.basicCase.data.procedureId
  );

  useEffect(() => {
    const init = async (): Promise<void> => {
      const fs = await initTemplateRecommendations(kase.basicCase, savedDefault);
      fieldsSummaryRef.current = fs;
    };

    if (kase.basicCase.data.procedureId) {
      void init();
    } else {
      console.debug('No procedure id');
    }
  }, [kase.basicCase.data.procedureId]);

  const evaluateValueToRecommend = (
    fieldId: string,
    values: IDynamicFieldValue,
    otherValues: IDynamicFieldValue | null,
    type: string,
    multi: boolean,
    oldValue: IDynamicFieldValue,
    oldOtherValue: IDynamicFieldValue | null
  ): IFieldRecommendation | null => {
    if (!values) {
      return null;
    }
    const otherChanged = otherValues && otherValues?.[0] !== oldOtherValue?.[0];
    if (otherChanged) {
      console.debug('Other changed. Ignoring.');
      return null;
    }
    if (type === 'text') {
      // @todo use the same logic to check if text field was updated
      console.debug('Ignoring text fields (need to handle debounce)');
      return null;
    }
    if (multi && values && oldValue) {
      if (values.length > oldValue.length) {
        return recommendTemplateAdd(
          savedDefault,
          fieldsSummaryRef.current,
          kase.basicCase.data.procedureTitle,
          fieldId,
          values,
          oldValue
        );
      } else if (values.length < oldValue.length) {
        return recommendTemplateRemove(
          savedDefault,
          fieldsSummaryRef.current,
          kase.basicCase.data.procedureTitle,
          fieldId,
          values,
          oldValue
        );
      } else {
        return null;
      }
    } else if (type === 'text1') {
      return recommendTemplateReplaceText(
        savedDefault,
        fieldsSummaryRef.current,
        kase.basicCase.data.procedureTitle,
        fieldId,
        values,
        oldValue
      );
    } else {
      if (values.length > 1) {
        throw new Error('Unexpected single field but multiple values');
      }
      return recommendTemplateReplace(
        savedDefault,
        fieldsSummaryRef.current,
        kase.basicCase.data.procedureTitle,
        fieldId,
        values,
        oldValue
      );
    }
  };

  return { evaluateValueToRecommend };
}

export default useTemplateRecommendations;
