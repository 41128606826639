// import { ROLES } from 'app/consts';
import { DynamicField, OTHER_OPTION } from './shared';
import {
  FIELD_TYPE,
  FIELD_VARIANT,
  type ICareTeamDefaults,
  type ICaseBasicData,
  type ICaseFieldValueData,
  type ICaseFullData,
  type ICaseReferencedData,
  type IDynamicField,
  type IDynamicFieldValues,
  type IFieldValuesEdits,
  type IField,
  type IGetFieldValuesCount,
  type IPMMFields,
  type IProcedure,
  type IProcedureSubTypeFieldOption,
  type IProcessedField,
  type IRoleData,
  type IServerProcessedField,
  type IUserFieldOption,
  type IUserFormField,
  type IUserRemovedFormField
} from 'app/mobxStore/types';
import { submitPmmField } from './submit';
import { getOptions } from './dynamicOptions';
import ErrorMonitor from '../../app/services/errorMonitor/errorMonitor';

export const getDefaultsFromProcedure = (procedureData: IProcedure): IPMMFields[] => {
  const pData = procedureData;
  if (pData === undefined || pData === null) {
    return [];
  }
  const { procedureFieldDefaults: fieldDefaults } = pData;
  const defaults = [];
  for (const { fieldId, values } of fieldDefaults) {
    defaults.push({
      id: fieldId,
      values,
      otherValues: []
    });
  }

  return defaults;
};

export const applyFieldValuesEdits = (
  userFieldOptions: string[],
  fieldValuesEdits: IFieldValuesEdits[] | undefined
): string[] => {
  if (!fieldValuesEdits || fieldValuesEdits.length === 0) {
    return userFieldOptions;
  }
  const filteredOptions = userFieldOptions.filter(
    opt => !fieldValuesEdits.find(fve => fve.value === opt)?.deleted
  );
  const updatedValues = filteredOptions.map(opt => {
    const edit = fieldValuesEdits.find(fve => fve.value === opt);
    if (edit?.newValue) {
      return edit.newValue;
    }
    return opt;
  });
  return updatedValues;
};

export const processFieldValues = (
  kase: ICaseFullData,
  userFormFields: IUserFormField[],
  userFieldOptions: IUserFieldOption[],
  userRemovedFormFields: IUserRemovedFormField[],
  fieldValuesEdits: IFieldValuesEdits[]
): Map<string, IProcessedField> => {
  const { caseFieldValues } = kase;
  const pData = kase.procedureData;
  if (pData === undefined || pData === null) {
    // return empty Record
    return new Map<string, IProcessedField>();
  }

  const {
    subType: {
      form: { formFields },
      procedureSubTypeFieldOptions: fieldOptions
    }
  } = pData;
  const filteredFormFields = formFields.filter(
    f => !userRemovedFormFields.some(rf => rf.fieldId === f.field.id)
  );
  filteredFormFields.forEach((f, idx: number) => {
    f.field.idx = idx;
  });
  pData.subType.form.formFields = filteredFormFields;

  const fieldValues: Record<string, IProcessedField> = {};
  const defParsedOptions: string[] = [];
  for (const { field } of filteredFormFields) {
    const fv = createField(
      field,
      defParsedOptions,
      userFieldOptions,
      fieldOptions,
      caseFieldValues,
      fieldValuesEdits
    );
    if (!fv) {
      continue;
    }
    fieldValues[field.id] = fv;
  }
  const userFilteredFormFields = userFormFields.filter(
    f => !userRemovedFormFields.some(rf => rf.fieldId === f.fieldId)
  );

  for (const field of userFilteredFormFields) {
    const formField = {
      hasOther: field.fieldType === FIELD_TYPE.SELECT,
      id: field.fieldId,
      name: field.fieldName,
      type: field.fieldType === FIELD_TYPE.SELECT ? FIELD_TYPE.CHIPS : FIELD_TYPE.TEXT,
      isPmm: true,
      idx: field.idx
    };
    const fv = createField(
      formField,
      defParsedOptions,
      userFieldOptions,
      [
        {
          fieldId: field.fieldId,
          clientId: '',
          options: ['Other']
        }
      ],
      caseFieldValues,
      fieldValuesEdits
    );

    filteredFormFields.splice(field.idx, 0, { field: formField });
    if (!fv) {
      continue;
    }
    fieldValues[field.fieldId] = fv;
  }
  // set fieldValues order by formFields
  const sortedFieldValues = new Map<string, IProcessedField>();
  filteredFormFields.forEach(({ field }, idx) => {
    if (!fieldValues[field.id]) {
      return;
    }
    fieldValues[field.id].idx = idx;
    sortedFieldValues.set(field.id, fieldValues[field.id]);
  });
  return sortedFieldValues;
};

const createField = (
  field: IField,
  defParsedOptions: string[],
  userFieldOptions: IUserFieldOption[],
  fieldOptions: IProcedureSubTypeFieldOption[],
  caseFieldValues: ICaseFieldValueData[],
  fieldValuesEdits: IFieldValuesEdits[]
): IProcessedField | undefined => {
  const userFieldOptionsPerField =
    userFieldOptions.find(opt => opt.fieldId === field.id)?.options ?? ([] as string[]);
  const fieldValuesEditsPerField = fieldValuesEdits.filter(opt => opt.fieldId === field.id);
  const parsedUserFieldOptionsPerField = applyFieldValuesEdits(
    userFieldOptionsPerField,
    fieldValuesEditsPerField
  );
  const fv: IProcessedField = {
    idx: field.idx,
    id: field.id,
    name: field.name,
    label: field.name,
    type: [FIELD_TYPE.CHIPS, FIELD_TYPE.SELECT].includes(field.type)
      ? FIELD_TYPE.SELECT
      : FIELD_TYPE.TEXT,
    multiple: field.type === FIELD_TYPE.CHIPS,
    hasOther: field.hasOther,
    parsedOptions: defParsedOptions,
    otherValue: [],
    value: [],
    userFieldOptions: parsedUserFieldOptionsPerField,
    fieldValuesEdits: fieldValuesEditsPerField
  };

  const fOpt = fieldOptions.find(
    opt => opt.fieldId === field.id && (opt.clientId === '' || opt.clientId === 'default')
  );

  if (fv.type === FIELD_TYPE.TEXT) {
    fv.parsedOptions = [];
  } else if (fOpt !== undefined) {
    const options = applyFieldValuesEdits(fOpt.options, fieldValuesEditsPerField);
    fv.parsedOptions = field.hasOther ? [...options, OTHER_OPTION] : [...options];
  } else {
    // eslint-disable-next-line no-continue
    return;
  }

  let defaults: string[] = [];
  let otherValue;

  const fieldValue = caseFieldValues.find(val => val.fieldId === field.id);
  if (fieldValue !== undefined) {
    defaults = fieldValue.values;
    otherValue = fieldValue.otherValues.length > 0 ? fieldValue.otherValues : [];
    fv.createdAt = fieldValue.createdAt;
    fv.updatedAt = fieldValue.updatedAt;
    fv.updatedBy = fieldValue.updatedByUser;
  }

  if (otherValue !== undefined) {
    fv.otherValue = otherValue;
    if (!fv.userFieldOptions.length) {
      fv.userFieldOptions = [...otherValue];
    }
  }
  fv.value = defaults;
  return fv;
};

const getFieldHint = (fieldId: string): string => {
  switch (fieldId) {
    case DynamicField.COMMENTS_TO_ANESTH:
      return 'comments_to_anesthesia_hint';
    case DynamicField.COMMENTS_TO_NURSING:
      return 'comments_to_nursing_hint';
    case DynamicField.ESTIMATED_CASE_LENGTH:
      return 'estimated_case_length_hint';
    case DynamicField.INSTRUCTIONS_FOR_2ND_PART:
      return 'second_part_of_the_case_hint';
  }
  return '';
};

export const processPMMFields = (
  caseData: ICaseBasicData,
  referencedData: ICaseReferencedData,
  userRoles: IRoleData[],
  editMode: boolean,
  getFieldValuesCount: (fieldId: string) => IGetFieldValuesCount,
  savedDefaults: ICareTeamDefaults
): IDynamicField[] => {
  const processedFields: IDynamicField[] = [];
  if (referencedData.fieldValues === undefined) {
    return [];
  }

  const keys = [...referencedData.fieldValues.keys()];
  keys.forEach((fieldId, idx) => {
    const field = referencedData.fieldValues.get(fieldId);
    if (field === undefined || referencedData.procedureData === undefined) {
      return;
    }
    const fvc = getFieldValuesCount(fieldId);
    const f: IDynamicField = {
      hasOther: field.hasOther,
      otherFieldProps: {
        value: field.otherValue,
        options: field.userFieldOptions
      },
      name: field.name,
      label: field.label,
      id: fieldId,
      isPmm: true,
      multiline: field.type === FIELD_TYPE.TEXT,
      options: getOptions(
        field.parsedOptions,
        fvc,
        field.otherValue,
        savedDefaults?.values[fieldId],
        field.userFieldOptions
      ),
      value: field.value,
      type: field.type,
      multiple: field.multiple,
      placeholder: getFieldHint(fieldId),
      withHint: getFieldHint(fieldId) !== '',
      disabled: false,
      variant: FIELD_VARIANT.STANDARD,
      editMode,
      createdAt: field.createdAt,
      update: async (values: IDynamicFieldValues): Promise<IServerProcessedField> => {
        ErrorMonitor.captureException(new Error('edit mode should not call update'));
        return {
          caseId: caseData.id,
          fieldId,
          values: '',
          otherValues: '',
          otherValuesOptions: []
        };
      },
      updatedAt: field.updatedAt,
      updatedBy: field.updatedBy,
      idx
    };
    if (editMode) {
      const update = async (values: IDynamicFieldValues): Promise<IServerProcessedField> => {
        return await submitPmmField(caseData.id, f, values, caseData.procedureId ?? '');
      };
      f.update = update;
    }
    processedFields.push(f);
    // Show comments to anesth/nursing only to relevant department. Show on top for anesth/nursing roles.
    // if (f.id === DynamicField.COMMENTS_TO_ANESTH) {
    //   if (userRoles.some(r => [ROLES.ANESTHESIA, ROLES.ANESTHESIA_RESIDENT].includes(r.role))) {
    //     processedFields.unshift(f);
    //   } else {
    //     processedFields.push(f);
    //   }
    // } else if (f.id === DynamicField.COMMENTS_TO_NURSING) {
    //   if (userRoles.some(r => [ROLES.NURSE, ROLES.NURSE_MANAGER].includes(r.role))) {
    //     processedFields.unshift(f);
    //   } else {
    //     processedFields.push(f);
    //   }
    // } else {
    //   processedFields.push(f);
    // }
  });
  return processedFields;
};
