import React, { type MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import clsx from 'clsx';
// import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import type { Control } from 'react-hook-form/dist/types/form';
import type { IUserSelectOption } from 'app/mobxStore/types';
import type { FORM_FIELDS_ENUM, IFormData } from './types';
import { useRootStore } from 'app/mobxStore';
import ModalScreen from 'app/components/modalScreen/ModalScreen';
import UserAvatar from 'app/components/userAvatar/UserAvatar';
import Button from 'app/components/buttons/Button';
import variables from 'app/utils/variables.module.scss';
import DoctorIcon from 'app/assets/icons/Doctor_icon';
import CloseIcon from 'app/assets/icons/Close_icon';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import EyeIcon from 'app/assets/icons/Eye_icon';
import type { FilterOptionsState } from '@mui/base/useAutocomplete/useAutocomplete';

interface IProps {
  control: Control<IFormData>;
  options: IUserSelectOption[];
  allValues: IFormData;
  isReadOnly?: boolean;
  name: FORM_FIELDS_ENUM.SURGEON;
  required: boolean;
  placeholder: string;
}

const UserSelect = (props: IProps): React.JSX.Element => {
  const { isReadOnly, control, options, name, required, placeholder } = props;
  const {
    field: { value, onChange, onBlur, ref }
  } = useController({
    rules: { required },
    name,
    control
  });
  const { t } = useTranslation();
  const [isOpen, seIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase;

  const filterOptions = (
    options: IUserSelectOption[],
    state: FilterOptionsState<IUserSelectOption>
  ): IUserSelectOption[] => {
    const filtered = options.filter(option => {
      const words = state.inputValue.toLowerCase().split(' ');
      const optionString =
        (option.firstName ?? '') + (option.lastName ?? '') + (option.label ?? '');
      return words.every(word => optionString.toLowerCase().includes(word));
    });

    return filtered;
  };

  const handleClose = async (e: MouseEvent): Promise<void> => {
    seIsOpen(false);
    setIsInputFocused(false);
  };

  const getButtonText = (user: IUserSelectOption | undefined): JSX.Element => {
    if (user === undefined) {
      return <div className="user-label">{t(placeholder)}</div>;
    }
    return <div className="user-label">{user.label}</div>;
  };
  // clear input value when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
      setIsInputFocused(false);
    }
  }, [isOpen]);
  const content = (
    <div className="modal-content">
      <div className="autocomplete-container">
        <Autocomplete
          ref={ref}
          onBlur={onBlur}
          freeSolo
          open
          disablePortal
          autoHighlight={true}
          onChange={(e, selectedValue) => {
            setInputValue('');
            onChange(selectedValue);
            seIsOpen(false);
          }}
          options={options}
          filterOptions={filterOptions}
          inputValue={inputValue}
          onInputChange={(event, value) => {
            setInputValue(value);
          }}
          ListboxProps={{
            // set list height to screen height - input container plus 16px list padding
            style: {
              maxHeight: 'calc(100vh - 124px)',
              overflow: 'auto'
            }
          }}
          sx={{
            '& input': {
              width: '100%',
              height: 44,
              backgroundColor: variables.grey2,
              borderRadius: 44,
              border: 'none',
              padding: '0 16px',
              boxSizing: 'border-box',
              fontSize: 14,
              color: variables.black,
              '&::placeholder': {
                color: variables.grey5
              },
              '&.focused': {
                outline: `1px solid ${variables.primaryPurple}`
              },
              '&:focus-visible': {
                outline: `1px solid ${variables.primaryPurple}`
              }
            },
            '& .input-container': {
              position: 'relative',
              padding: '32px 24px',
              display: 'flex',
              height: 108,
              boxSizing: 'border-box',
              borderBottom: '1px solid',
              borderColor: variables.grey2,
              '& .back-button': {
                flexShrink: 0,
                marginRight: '8px',
                '.svg-icon': {
                  height: 14
                }
              },
              '& .clear-button': {
                height: '42px',
                width: '42px',
                backgroundColor: variables.white,
                position: 'absolute',
                right: 25,
                top: 33
              }
            }
          }}
          // PaperComponent={({ children }) => (
          //   <Paper
          //     style={{
          //       width: '100%',
          //       height: 'auto',
          //       boxShadow: 'none'
          //     }}
          //   >
          //     {children}
          //   </Paper>
          // )}
          renderInput={params => {
            return (
              <div ref={params.InputProps.ref} className="input-container">
                <Button
                  classNames="btn circle-button back-button"
                  startIcon={<ArrowLeftIcon height={10} stroke={variables.grey6} />}
                  onClick={handleClose}
                />
                <input
                  type="text"
                  onClick={() => {
                    setIsInputFocused(true);
                  }}
                  {...params.inputProps}
                  className={clsx('text-input', { focused: isInputFocused })}
                  placeholder={t('searchTeamMembers').toString()}
                />
                {inputValue !== '' && (
                  <Button
                    classNames="btn circle-button clear-button"
                    startIcon={<CloseIcon height={10} stroke={variables.grey6} />}
                    onClick={async (e: MouseEvent) => {
                      setInputValue('');
                    }}
                  />
                )}
              </div>
            );
          }}
          renderOption={(props, option: IUserSelectOption) => {
            return (
              <li {...props} className="list-option">
                <UserAvatar user={option} size={'medium'} />
                {option.label}
              </li>
            );
          }}
        />
      </div>
    </div>
  );

  const btnText = getButtonText(value);
  const isSurgeonSeen = openedCase?.basicCase.getUserLastSeen(value?.userId ?? '');

  return (
    <div className="user-select">
      <Button
        classNames={clsx('chip-button', { 'has-value': value !== undefined })}
        buttonContent={btnText}
        disabled={isReadOnly}
        startIcon={
          value !== undefined ? <UserAvatar user={value} size={'medium'} /> : <DoctorIcon />
        }
        endIcon={isSurgeonSeen ? <EyeIcon className="seen-icon" /> : undefined}
        onClick={async (e: MouseEvent) => {
          seIsOpen(true);
        }}
        isShowMandatory={required && value === undefined}
      />
      <ModalScreen
        isOpen={isOpen}
        classNames={'full-height user-modal'}
        content={content}
        handleClose={() => {
          seIsOpen(false);
        }}
      />
    </div>
  );
};

export default UserSelect;
