import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import type { Control } from 'react-hook-form/dist/types/form';
import type {
  IDynamicField,
  IDynamicFieldValues,
  IFieldValuesEdits,
  ISelectOption
} from 'app/mobxStore/types';
import { useController } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { log } from 'debug';
import { OTHER_OPTION } from '../../../utils/form/shared';
import OtherChips from './OtherChips';
import { fieldNeedsAttention } from './helper';
import { useRootStore } from '../../mobxStore';
import { useLongPress } from 'use-long-press';
import { useOutsideClick } from 'app/utils/useClickOutside';
import AmplService from 'app/services/amplService';
import CloseIcon from 'app/assets/icons/Close_icon';
import variables from 'app/utils/variables.module.scss';
import apiService from '../../services/apiService/apiService';
import amplService from '../../services/amplService/amplService';
import { applyFieldValuesEdits } from '../../../utils/form/PMMFields';

interface IProps {
  control: Control<any>;
  field: IDynamicField;
  allValues: IDynamicFieldValues;
  isEditTemplate: boolean;
}

const Chips = (props: IProps): React.JSX.Element => {
  const { allValues, control, field: dField, isEditTemplate } = props;
  const { field } = useController({
    name: dField.id,
    control
  });
  const fieldValue = field.value as string[];
  const { caseStore, userStore } = useRootStore();
  const { openedCase } = caseStore;
  const removeChipRef = useOutsideClick(() => {
    caseStore.setRemoveChipsMode(false);
  }, 'remove-chip-button');

  if (!openedCase) {
    throw new Error('No case found');
  }

  const currentUserLastSeen = openedCase.basicCase.getUserLastSeen(userStore.loggedInUser.data.id);

  const isOptionSelected = (option: ISelectOption, value: string[]): boolean => {
    if (value === null || value === undefined) {
      log('value is null');
      log(dField);

      return false;
    }
    return value.length > 0 && value.findIndex(o => o === option.value) > -1;
  };

  const toggleValue = (
    option: ISelectOption,
    value: string[],
    setValue: (event: string[]) => void
  ): void => {
    const nextState = isOptionSelected(option, value)
      ? value.filter(el => el !== option.value)
      : [...value, option.value];

    setValue(nextState);
  };

  const bindLongPress = useLongPress(
    () => {
      AmplService.sendEvent(AmplService.EVENTS.CASE_OPTION_LONG_PRESS);
      caseStore.setRemoveChipsMode(!caseStore.isRemoveChipsMode);
    },
    { threshold: 600 }
  );

  const handleRemoveChip = (dField: IDynamicField, option: ISelectOption): void => {
    const object: IFieldValuesEdits = {
      deleted: true,
      procedureId: openedCase.basicCase.data.procedureId as string,
      siteId: openedCase.basicCase.data.siteId,
      fieldId: dField.id,
      value: option.value
    };
    if (!isEditTemplate) {
      void apiService.upsertFieldValuesEdits(object);
    }
    openedCase.updateFieldValuesEditsToStore(dField.id, object);
    amplService.sendCaseEvent(amplService.EVENTS.CASE_OPTION_REMOVED, openedCase.basicCase, {
      [amplService.ATTRIBUTES.FIELD_ID]: dField.id,
      [amplService.ATTRIBUTES.OPTION]: option.value
    });
  };
  const fv = openedCase.referencedData.fieldValues.get(dField.id);

  const fieldValuesEdits = fv?.fieldValuesEdits ?? [];
  const options = applyFieldValuesEdits(
    dField.options.map(opt => opt.value),
    fieldValuesEdits
  );
  const filteredOptions = dField.options.filter(opt => options.includes(opt.value));
  const buttons = filteredOptions.map(option => {
    if (option.value === OTHER_OPTION) {
      return (
        <OtherChips
          isEditTemplate={isEditTemplate}
          allValues={allValues}
          control={control}
          dField={dField}
          key={option.value}
          field={field}
          option={option}
          isOptionSelected={isOptionSelected}
          toggleValue={toggleValue}
        />
      );
    }
    return (
      <div className="chip-button-wrapper" key={option.value}>
        <Button
          className={clsx('btn chip-button toggle-chip', {
            selected: isOptionSelected(option, fieldValue),
            isNotClickable: caseStore.isRemoveChipsMode
          })}
          onClick={() => {
            if (caseStore.isRemoveChipsMode) {
              return;
            }
            toggleValue(option, fieldValue, field.onChange);
          }}
          {...bindLongPress()}
        >
          {option.label}
        </Button>
        {caseStore.isRemoveChipsMode && (
          <div className="remove-chip-button-wrapper" ref={removeChipRef}>
            <Button
              id="remove-chip-button"
              className={clsx('btn remove-chip-button')}
              onClick={() => {
                if (isOptionSelected(option, fieldValue)) {
                  toggleValue(option, fieldValue, field.onChange);
                }
                handleRemoveChip(dField, option);
              }}
            >
              <div className="icon-wrapper">
                <CloseIcon stroke={variables.white} />
              </div>
            </Button>
          </div>
        )}
      </div>
    );
  });

  return (
    <FormControl className="form-group">
      <div
        className={clsx('form-group-label', {
          needsAttention: fieldNeedsAttention(
            dField,
            userStore.loggedInUser.data.id,
            currentUserLastSeen
          )
        })}
      >
        <div className="label-container">{dField.label}</div>
      </div>
      <>{buttons}</>
    </FormControl>
  );
};

export default observer(Chips);
