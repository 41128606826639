import React, { useState } from 'react';

import type { IDynamicField, ISelectOption } from 'app/mobxStore/types';
import type { ControllerRenderProps } from 'react-hook-form';
import OtherMultiTextField from './OtherMultiTextField';
import type { Control } from 'react-hook-form/dist/types/form';
import { observer } from 'mobx-react-lite';
import AddYourOwnChip from './AddYourOwnChip';
interface IProps {
  index: number;
  control: Control<any>;
  dField: IDynamicField;
  field: ControllerRenderProps<any, string>;
  option: ISelectOption;
  toggleValue: (
    option: ISelectOption,
    value: string[],
    setValue: (...event: any[]) => void
  ) => void;
  isOptionSelected: (option: ISelectOption, value: string[]) => boolean;
  value: string;
  onEditEnd: () => void;
  onEditStart: (idx: number) => void;
  isLast: boolean;
  isEditTemplate: boolean;
}

const OtherChip = (props: IProps): React.JSX.Element => {
  const {
    isEditTemplate,
    index,
    value,
    control,
    dField,
    field,
    option,
    toggleValue,
    isOptionSelected
  } = props;
  const isSelected = isOptionSelected(option, field.value);
  const [isEditing, setIsEditing] = useState(false);
  const doneEditing = (count: number): void => {
    setIsEditing(false);
    props.onEditEnd();
  };
  const startEditing = (): void => {
    props.onEditStart(index);
  };
  if (isSelected && isEditing) {
    return (
      <OtherMultiTextField
        isEditTemplate={isEditTemplate}
        value={value}
        index={index}
        control={control}
        field={dField}
        doneEditing={doneEditing}
      />
    );
  }

  return (
    <AddYourOwnChip
      isEditTemplate={isEditTemplate}
      isLast={props.isLast}
      field={field}
      option={option}
      isSelected={isSelected}
      value={value}
      toggleValue={toggleValue}
      setIsEditing={isEditing => {
        if (isEditing) {
          startEditing();
        }
        setIsEditing(isEditing);
      }}
    />
  );
};

export default observer(OtherChip);
