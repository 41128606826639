import React, { useState } from 'react';
import Field from 'app/components/form/Field';
import { observer } from 'mobx-react-lite';
import type { Control, FormState } from 'react-hook-form/dist/types/form';
import type {
  IDynamicField,
  IDynamicFieldValue,
  IDynamicFieldValues,
  IFieldRecommendation,
  IFullCase
} from 'app/mobxStore/types';
import useTemplateRecommendations from '../caseViews/hooks/useTemplateRecommendations';
import { useRootStore } from '../../mobxStore';
import RecommendationsModal from './RecommendationsModal';
import { DynamicField } from '../../../utils/form/shared';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import AddFieldButton from 'app/components/huddleTemplate/AddFieldButton';

interface IProps {
  fields: IDynamicField[];
  control: Control<IDynamicFieldValues>;
  allValues: IDynamicFieldValues;
  setFocus: (id: string) => void;
  formState: FormState<IDynamicFieldValues>;
  submitChange: boolean;
  isEditTemplate?: boolean;
  fieldsOrder?: string[];
}

const Form = (props: IProps): React.JSX.Element => {
  const {
    fieldsOrder,
    formState,
    setFocus,
    allValues,
    fields,
    control,
    submitChange,
    isEditTemplate
  } = props;
  const { caseStore, metaDataStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const [recommendation, setRecommendation] = useState<IFieldRecommendation | null>(null);
  const { evaluateValueToRecommend } = useTemplateRecommendations({
    case: openedCase
  });

  const handleFieldChangeVendor = (
    values: IDynamicFieldValue,
    otherValues: IDynamicFieldValue | null,
    oldValue: IDynamicFieldValue,
    oldOtherValue: IDynamicFieldValue | null
  ): void => {
    const newValues = otherValues ? [...values, ...otherValues] : values;
    const oldValues = oldOtherValue ? [...oldValue, ...oldOtherValue] : oldValue;
    const addedValues = newValues.filter(value => !oldValues.includes(value));
    const removedValues = oldValues.filter(value => !newValues.includes(value));

    addedValues.forEach(value => {
      const usrs = metaDataStore.getVendorUsers(openedCase.basicCase.data.siteId, value);
      usrs.forEach(u => {
        if (openedCase.basicCase.data.caseFollowers.find(f => f.userId === u.id)) {
          // already following
          return;
        }
        openedCase.basicCase.upsertFollowerWithLastSeenToStore(u.id, null);
        void openedCase.basicCase.upsertFollowerToServer(u.id);
        const msg = t('vendor_rep_added_to_huddle', { name: u.nickName });
        toast.success(msg);
      });
    });

    removedValues.forEach(value => {
      const usrs = metaDataStore.getVendorUsers(openedCase.basicCase.data.siteId, value);
      usrs.forEach(u => {
        if (!openedCase.basicCase.data.caseFollowers.find(f => f.userId === u.id)) {
          // not following
          return;
        }
        void openedCase.basicCase.removeFollowerFromServer(u.id);
        openedCase.basicCase.removeFollowerFromStore(u.id);
        const msg = t('vendor_rep_removed_from_huddle', { name: u.nickName });
        toast.success(msg);
      });
    });
  };

  const handleFieldValueChangeRec = (
    fieldId: string,
    values: string[],
    otherValues: string[] | null,
    type: string,
    multi: boolean,
    oldValue: string[],
    oldOtherValue: string[] | null
  ): void => {
    const rec = evaluateValueToRecommend(
      fieldId,
      values,
      otherValues,
      type,
      multi,
      oldValue,
      oldOtherValue
    );
    console.debug('recommendation', JSON.stringify(rec));
    setRecommendation(rec);
  };

  const handleFieldValueChange = (
    fieldId: string,
    values: IDynamicFieldValue,
    otherValues: IDynamicFieldValue | null,
    type: string,
    multi: boolean,
    oldValue: IDynamicFieldValue,
    oldOtherValue: IDynamicFieldValue | null
  ): void => {
    handleFieldValueChangeRec(fieldId, values, otherValues, type, multi, oldValue, oldOtherValue);

    if (fieldId === DynamicField.VENDOR) {
      handleFieldChangeVendor(values, otherValues, oldValue, oldOtherValue);
    }
  };
  if (fieldsOrder) {
    fields.sort((a, b) => fieldsOrder.indexOf(a.id) - fieldsOrder.indexOf(b.id));
  }
  return (
    <form className="form-container">
      {recommendation && (
        <RecommendationsModal
          recommendation={recommendation}
          setRecommendation={setRecommendation}
        />
      )}
      {fields.map((field, idx) => (
        <div key={field.id}>
          {isEditTemplate && <AddFieldButton field={field} idx={idx} />}
          <Field
            isDirty={formState.dirtyFields[field.id] !== undefined}
            isOtherDirty={formState.dirtyFields[`${field.id}-other`] !== undefined}
            setFocus={setFocus}
            allValues={allValues}
            control={control}
            key={field.id}
            field={field}
            onValueChange={handleFieldValueChange}
            submitChange={submitChange}
            isEditTemplate={isEditTemplate}
          />
          {isEditTemplate && fields[fields.length - 1].id === field.id && (
            <AddFieldButton field={field} idx={idx + 1} />
          )}
        </div>
      ))}
    </form>
  );
};
export default observer(Form);
