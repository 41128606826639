import React, { type MouseEvent, useState } from 'react';
import type { IDynamicField, IFullCase } from 'app/mobxStore/types';
import { useRootStore } from '../../mobxStore';
import { useTranslation } from 'react-i18next';
import Button from 'app/components/buttons/Button';
import ConfirmationPanel from 'app/components/confirmationPanel/ConfirmationPanel';
import TrashIcon from 'app/assets/icons/Trash_icon';
import AmplService from '../../services/amplService/amplService';

interface IProps {
  field: IDynamicField;
}

const RemoveFieldButton = (props: IProps): React.JSX.Element => {
  const { field } = props;
  const { caseStore } = useRootStore();
  const openedCase = caseStore.openedCase as IFullCase;
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { t } = useTranslation();

  const handleRemoveField = async (): Promise<void> => {
    openedCase.removeField(field);
    AmplService.sendCaseEvent(
      AmplService.EVENTS.EDIT_PREFERENCES_REMOVE_FIELD_CONFIRM_PANEL,
      openedCase?.basicCase,
      { [AmplService.ATTRIBUTES.CONFIRM]: true }
    );
  };

  const handleClose = async (): Promise<void> => {
    setIsPanelOpen(false);
    AmplService.sendCaseEvent(
      AmplService.EVENTS.EDIT_PREFERENCES_REMOVE_FIELD_CONFIRM_PANEL,
      openedCase?.basicCase,
      { [AmplService.ATTRIBUTES.CONFIRM]: false }
    );
  };

  return (
    <div className="remove-field-wrapper">
      <Button
        classNames="btn circle-button remove-field-button"
        startIcon={<TrashIcon />}
        onClick={async (e: MouseEvent) => {
          setIsPanelOpen(true);
          AmplService.sendCaseEvent(
            AmplService.EVENTS.EDIT_PREFERENCES_REMOVE_FIELD,
            openedCase?.basicCase
          );
        }}
      />
      <ConfirmationPanel
        title={t('removeFieldConfirmationTitle', { fieldName: field.name })}
        buttons={[
          {
            buttonText: t('removeFieldConfirmationYes'),
            onClick: handleRemoveField,
            variant: 'outlined',
            closeOnClick: true,
            key: 'yes'
          },
          {
            buttonText: t('back'),
            onClick: handleClose,
            variant: 'secondary',
            closeOnClick: true,
            key: 'no'
          }
        ]}
        isShowPanel={isPanelOpen}
        setIsShowPanel={isTrue => {
          if (isTrue) {
            return;
          }
          setIsPanelOpen(false);
        }}
      />
    </div>
  );
};

export default RemoveFieldButton;
