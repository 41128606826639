import React, { useCallback, useState } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import { useOutsideClick } from 'app/utils/useClickOutside';
import ClassicDatePicker from 'app/components/datePickers/classicDatePicker/ClassicDatePicker';
import WeekDayPicker from 'app/components/datePickers/horizontalDatePicker/WeekDayPicker';
import MyCasesToggle from './MyCasesToggle';
import clsx from 'clsx';
import variables from 'app/utils/variables.module.scss';
import './Filters.scss';

import IconButton from '@mui/material/IconButton';
import CalendarIcon from 'app/assets/icons/CalendarAlt_icon';
import useOurSearchParams from '../../caseViews/hooks/useOurSearchParams';
import { toZonedTime } from 'date-fns-tz';

const Filters = (): React.JSX.Element => {
  const { caseStore, showCasesToggle, metaDataStore } = useRootStore();
  const [isClassicToggled, setIsClassicToggled] = useState(false);
  const classicPickerRef = useOutsideClick(() => {
    setIsClassicToggled(false);
  }, undefined);
  const { ourSearchParams, setOurSearchParams } = useOurSearchParams();

  const handleClickDay = (day: Date): void => {
    if (ourSearchParams.date?.valueOf() === day.valueOf()) {
      return;
    }
    setOurSearchParams({ date: day }, false);
    setIsClassicToggled(false);
  };
  const handleClickDayCallback = useCallback(handleClickDay, [ourSearchParams]);

  const navigateToToday = (): void => {
    const tz = metaDataStore.tz;
    const today = toZonedTime(new Date(), tz);
    handleClickDay(today);
    setIsClassicToggled(false);
  };

  const getDateLabel = (day: Date): string => {
    const date = day.toLocaleString('default', { day: 'numeric' });
    const month = day.toLocaleString('default', { month: 'long' });
    const year = day.toLocaleString('default', { year: 'numeric' });
    const label = date + ' ' + month + ', ' + year;

    return label;
  };

  const dateLabel = getDateLabel(caseStore.selectedDateFilter);
  const isCatchUp = caseStore.casesFilter === 'catchUp';

  return (
    <div className="case-filters">
      {showCasesToggle && <MyCasesToggle />}
      {!isCatchUp && (
        <>
          <WeekDayPicker handleClickDay={handleClickDayCallback} />
          <div className={clsx('classic-picker-container', { minimized: !isClassicToggled })}>
            <div className="expand-container" ref={classicPickerRef}>
              <div className="toggle-row">
                <div
                  className="date-label"
                  onClick={() => {
                    setIsClassicToggled(false);
                  }}
                >
                  {dateLabel}
                </div>
                <IconButton
                  id="date-picker-toggle-button"
                  className="toggle-button"
                  onClick={() => {
                    setIsClassicToggled(!isClassicToggled);
                  }}
                >
                  <CalendarIcon
                    height={28}
                    color={isClassicToggled ? variables.primaryPurple : variables.grey6}
                  />
                </IconButton>
              </div>
              <ClassicDatePicker
                handleClickDay={handleClickDayCallback}
                selectedDay={caseStore.selectedDateFilter}
                navigateToToday={navigateToToday}
                shouldScroll
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Filters);
